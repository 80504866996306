import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const theme = createTheme({
   direction: 'ltr',
});

// Create rtl cache
const cacheRtl = createCache({
   key: 'muirtl',
   stylisPlugins: [prefixer, rtlPlugin],
});

ReactDOM.render(
   <React.StrictMode>
      <CacheProvider value={cacheRtl}>
         <ThemeProvider theme={theme}>
            <App />
         </ThemeProvider>
      </CacheProvider>
   </React.StrictMode>,
   document.getElementById('root')
);

// Register Service Worker for PWA
serviceWorkerRegistration.register();

// Logging Vitals ( pass console.log )
// reportWebVitals(console.log);

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import Grid from '@mui/material/Grid';
import logo from '../logo.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExtractData from "../Helpers/ExtractData";

export default function TopBar(props) {
  return (
    <AppBar position='fixed' sx={{
      backgroundColor: 'none'
    }}>
      <Toolbar
        className={props.theme === false ? 'topbar-gradient-color-light' : 'topbar-gradient-color-dark'}
        sx={{
          height: '90px !important',
          minHeight: '90px !important',
          padding: '0px 20px',
          fontSize: '12px',
          fontWeight: 700,
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{ paddingTop: '5px' }}
        >
          <Grid item xs={2}>
            <img style={{ width: '60px' }} src={logo} alt='logo' />
          </Grid>

          <Grid item xs={8}>
            <Typography
              variant='h6'
              component='div'
              sx={{ flexGrow: 1, fontSize: '16px' }}
            >
              <div>{ExtractData.getCityAltName(props.currentCountry,props.currentCity, props.currentLang)}</div>
              <div
                style={{
                  fontSize: '10px',
                  fontWeight: 300,
                }}
              >
                {ExtractData.getCountryAltName(props.currentCountry, props.currentLang)}
              </div>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={props.toggleDrawer(true)}
            >
              <SettingsIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          {(props.currentPage === 'DailyTimes' || props.currentPage === 'ExtraMaterial') && (<>
            <Grid
              item
              xs={12}
              sx={{ fontWeight: props.currentYear !== new Date().getFullYear() ? 500 : 300, textAlign: 'center', fontSize: '12px', color: props.currentYear !== new Date().getFullYear() ? '#ffca28' :"#fff" }}
            >
              {props.todayDate.toLocaleDateString(props.lang[props.currentLang].locale, {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })} {props.currentYear}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ fontWeight: 300, textAlign: 'center', fontSize: '12px' }}
            >
              {props.todaySalahTimes?.hijri_date}
            </Grid>
          </>
          )}
          {props.currentPage === 'MonthlyTimes' && (
            <Grid item xs={2}>
              <IconButton
                sx={{ padding: '0px' }}
                size='small'
                color='inherit'
                onClick={() => props.changeSelectedMonth(-1)}
                disabled={props.selectedMonth.getMonth() === 0}
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
          )}
          {props.currentPage === 'MonthlyTimes' && (
            <Grid item xs={5}>
              <Typography
                sx={{ fontWeight: 500, textAlign: 'center', fontSize: '14px', color: props.currentYear !== new Date().getFullYear() ? '#ffca28' :"#fff" }}
              >
                {props.selectedMonth.toLocaleDateString(props.lang[props.currentLang].locale, {
                  month: 'long',
                })} {props.currentYear}
              </Typography>
            </Grid>
          )}
          {props.currentPage === 'MonthlyTimes' && (
            <Grid item xs={2}>
              <IconButton
                size='small'
                sx={{ padding: '0px' }}
                color='inherit'
                onClick={() => props.changeSelectedMonth(1)}
                disabled={props.selectedMonth.getMonth() === 11}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import './DailyTimes.css';
import { Typography } from '@mui/material';

const salahNames = [
  {
    salahGerman: 'Fadjr',
    salahArabic: 'الفجر',
    var_name: 'p1',
  },
  {
    salahGerman: 'Shuruk',
    salahArabic: 'الشروق',
    var_name: 'p2',
  },
  {
    salahGerman: 'Duhr',
    salahArabic: 'الظهر',
    var_name: 'p3',
  },
  {
    salahGerman: 'Assr',
    salahArabic: 'العصر',
    var_name: 'p4',
  },
  {
    salahGerman: 'Maghrib',
    salahArabic: 'المغرب',
    var_name: 'p5',
  },
  {
    salahGerman: 'Ishaa',
    salahArabic: 'العشاء',
    var_name: 'p6',
  },
];

export default function DailyTimes(props) {
  return (
      <div className='fullscreen'>
        <CssBaseline />
        {salahNames.map(({ salahArabic, salahGerman, var_name }, index) => (
          <Grid
            className='onesixth'
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            key={index}
            sx={{
              color: props.theme === false ? '#111': '#FFF',
              backgroundColor:
                 props.theme === false
                    ? index % 2 === 1
                       ? '#F5F4F4'
                       : '#FFFFFF'
                    : index % 2 === 1
                       ? '#1E1E1E'
                       : '#191c19'
            }}
          >
            <Grid item xs={6} sx={{}}>
              <Typography sx={{ fontWeight: 'medium' }} variant='h6'>
                {salahGerman}
              </Typography>
              <Typography sx={{ fontWeight: 'light' }} variant='h6'>
                {salahArabic}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ color: '072227' }}> {/* sx={{ color: '072227' }} */}
              <Typography variant='h4' sx={{ fontWeight: 500 }}>
                {props.todaySalahTimes[var_name]['t']}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
  );
}

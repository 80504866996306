import React from 'react';
import './App.css';
import TopBar from './TopBar/TopBar.js';
import SideBar from './SideBar/SideBar.js';
import DailyTimes from './DailyTimes/DailyTimes.js';
import MonthlyTimes from './MonthlyTimes/MonthlyTimes.js';
import BottomNavigation from './BottomNavigation/BottomNavigation.js';
import Requests from './API/Request.js';
import ExtractData from './Helpers/ExtractData.js';
import Grid from '@mui/material/Grid';
import Dexie from 'dexie';
import languagesFile from './languages.json';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

const RTLtheme = createTheme({
   direction: 'rtl',
});
const LTRtheme = createTheme({
   direction: 'ltr',
});

// Create rtl cache
const cacheRtl = createCache({
   key: 'muirtl',
   stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLTR = createCache({
   key: 'muiltr',
});

class App extends React.Component {
   constructor(props) {
      super(props);
      this.formRef = React.createRef();
      this.state = {
         // Internal State
         currentPage: 'DailyTimes',
         loading: true,
         offline: false,
         openSideBar: false,
         dexieInstance: null,
         darkMode: JSON.parse(localStorage.getItem('darkmode')) ? JSON.parse(localStorage.getItem('darkmode')) : false,
         // Language data
         lang: languagesFile,
         currentLang: JSON.parse(localStorage.getItem('language')) ? JSON.parse(localStorage.getItem('language')) : 'en',
         // App Data
         // cities & countries
         cities: ExtractData.getCityNames('Germany'),
         countries: ExtractData.getCountryNames(),
         // Global data
         data: null,
         // Current City that has its data displayed
         currentCity: 'Aachen',
         currentCountry: 'Germany',
         // City and Country selected in Sidebar
         selectedCity: 'Aachen',
         selectedCountry: 'Germany',
         fajr_no_taqdir: true,
         selectedYear: new Date().getFullYear(),
         currentYear: new Date().getFullYear(),
         // DST form
         observe_dst: true,
         dst_from_date: null,
         dst_to_date: null,
         dst_deviation: 1,
         // Daily data
         todaySalahTimes: null,
         todayDate: new Date(),
         // Monthly data
         selectedMonth: new Date(),
         monthlySalahTimes: null,
      };
   }

   setDarkMode = (darkMode) => {
      localStorage.setItem('darkmode', !darkMode);
      this.setState({
         darkMode: !darkMode,
      });
   };

   async componentDidMount() {
      // Check if their is a name of a city
      let dexieInstance = new Dexie('city_name');
      dexieInstance.version(1).stores({
         city_name: 'key,name,timestamp',
      });
      const savedCityName = await dexieInstance.city_name.get('city_name');
      if (savedCityName) {
         this.setState({
            currentCity: savedCityName.name.split(';')[0],
            currentCountry: savedCityName.name.split(';')[1],
            selectedCity: savedCityName.name.split(';')[0],
            selectedCountry: savedCityName.name.split(';')[1],
            cities: ExtractData.getCityNames(savedCityName.name.split(';')[1]),
         });
      }
      this.setState({ dexieInstance });

      // Set online and offline handlers
      window.addEventListener('online', this.handleConnection);
      window.addEventListener('offline', this.handleConnection);

      // Update the time and today data
      setInterval(() => {
         const todayDate = new Date();
         let todaySalahTimes = null;
         if (this.state.data != null) {
            todaySalahTimes = ExtractData.extractDailySalahTimes(this.state.data, todayDate, this.state.currentYear);
         }
         this.setState({ todayDate, todaySalahTimes });
      }, 1000);

      this.getNewCityData(
         this.state.currentCity,
         this.state.currentCountry,
         this.state.fajr_no_taqdir,
         this.state.currentYear,
         this.state.observe_dst,
         this.state.dst_from_date,
         this.state.dst_to_date,
         this.state.dst_deviation
      );
   }

   getNewCityData = async (cityName, countryName, fajr_no_taqdir, currentYear, observe_dst, dst_from_date, dst_to_date, dst_deviation) => {
      // Set loading
      this.setState({ loading: true });

      // Get the Salah Times data
      const data = await Requests.getSalahData(
         cityName,
         countryName,
         fajr_no_taqdir,
         currentYear,
         observe_dst,
         dst_from_date,
         dst_to_date,
         dst_deviation
      );

      // Check data
      let loading = false;
      if (!data) loading = true;

      // Select Today's Data
      const todaySalahTimes = ExtractData.extractDailySalahTimes(data, this.state.todayDate, this.state.currentYear);

      // Select Month's Data
      const monthlySalahTimes = ExtractData.extractMonthlySalahTimes(data, this.state.selectedMonth);

      // Update State
      this.setState({ data, todaySalahTimes, monthlySalahTimes, loading });
   };

   setSelectedYear = (newValue, changecity) => {
      this.setState({ selectedYear: newValue });
      if (changecity) this.changeCurrentCity({ selectedYear: newValue });
   };

   setCurrentYear = (newValue) => {
      this.setState({ currentYear: newValue });
   };

   setcurrentPage = (newValue) => {
      this.setState({ currentPage: newValue });
   };

   setSelectedCity = async (newValue, changecity) => {
      this.setState({ selectedCity: newValue });
      if (changecity) this.changeCurrentCity({ selectedCity: newValue });
   };

   set_fajr_no_taqdir = async (newValue, changecity) => {
      this.setState({ fajr_no_taqdir: newValue });
      if (changecity) this.changeCurrentCity({ fajr_no_taqdir: newValue });
   };

   set_observe_dst = async (newValue) => {
      this.setState({ observe_dst: newValue });
   };
   set_dst_from_date = async (newValue, changecity) => {
      this.setState({ dst_from_date: newValue });
      if (changecity) this.changeCurrentCity({ dst_from_date: newValue });
   };
   set_dst_to_date = async (newValue, changecity) => {
      this.setState({ dst_to_date: newValue });
      if (changecity) this.changeCurrentCity({ dst_to_date: newValue });
   };
   set_dst_deviation = async (newValue, changecity) => {
      this.setState({ dst_deviation: newValue });
      if (changecity) this.changeCurrentCity({ dst_deviation: newValue });
   };

   setSelectedCountry = async (newValue) => {
      this.setState({ selectedCountry: newValue, selectedCity: '', cities: ExtractData.getCityNames(newValue) });
   };

   changeCurrentCity = async (changedValues, checkFormValid = false) => {
      const tempState = { ...this.state, ...changedValues };
      const { selectedCountry, selectedCity, selectedYear, fajr_no_taqdir, observe_dst, dst_from_date, dst_to_date, dst_deviation } = tempState;

      if (selectedCountry === '' || selectedCountry === null || selectedCity === '' || selectedCity === null) return;
      try {
         await this.getNewCityData(
            selectedCity,
            selectedCountry,
            fajr_no_taqdir,
            selectedYear,
            observe_dst,
            dst_from_date,
            dst_to_date,
            dst_deviation
         );
         await this.state.dexieInstance.city_name.put({
            key: 'city_name',
            name: selectedCity + ';' + selectedCountry,
            timestamp: Date.now(),
         });
         this.setState({ currentCity: selectedCity, currentCountry: selectedCountry, currentYear: selectedYear });
      } catch (error) {
         console.error(error);
      }
   };

   toggleDrawer = (openSideBar) => (event) => {
      this.setState({ openSideBar });
   };

   changeLanguage = (event, value) => {
      if (!value) return;
      localStorage.setItem('language', JSON.stringify(value));
      this.setState({ currentLang: value });
   };

   changeSelectedMonth = (changeValue) => {
      let currentMonth = this.state.selectedMonth.getMonth();

      // Maintain bounds of 0 -> 11
      if (changeValue === 1) {
         currentMonth = currentMonth !== 11 ? currentMonth + 1 : currentMonth;
      } else if (changeValue === -1) {
         currentMonth = currentMonth !== 0 ? currentMonth - 1 : currentMonth;
      }

      const selectedMonth = new Date(this.state.selectedMonth.getFullYear(), currentMonth, 2);

      // Select Month's Data
      const monthlySalahTimes = ExtractData.extractMonthlySalahTimes(this.state.data, selectedMonth);

      this.setState({ monthlySalahTimes, selectedMonth });
   };

   handleConnection = () => {
      if (navigator.onLine) {
         // handle online status
         console.log('online');
         this.setState({ offline: false });
      } else {
         // handle offline status
         console.log('offline');
         this.setState({ offline: true });
      }
   };

   downloadJSON = () => {
      Requests.DownloadJSON(this.state.data);
   };
   DownloadCSV = () => {
      Requests.DownloadCSV(
         this.state.currentCity,
         this.state.currentCountry,
         this.state.fajr_no_taqdir,
         this.state.currentYear,
         this.state.observe_dst,
         this.state.dst_from_date,
         this.state.dst_to_date,
         this.state.dst_deviation
      );
   };
   /* DownloadCalendar = () => {
      Requests.DownloadCalendar(
         this.state.currentCity,
         this.state.currentCountry,
         this.state.fajr_no_taqdir,
         this.state.currentYear,
         this.state.observe_dst,
         this.state.dst_from_date,
         this.state.dst_to_date,
         this.state.dst_deviation
      );
   }; */
   DownloadExcel = () => {
      Requests.DownloadExcel(
         this.state.currentCity,
         this.state.currentCountry,
         this.state.fajr_no_taqdir,
         this.state.currentYear,
         this.state.observe_dst,
         this.state.dst_from_date,
         this.state.dst_to_date,
         this.state.dst_deviation
      );
   };
   DownloadPDF = () => {
      Requests.DownloadPDF(
         this.state.currentCity,
         this.state.currentCountry,
         this.state.fajr_no_taqdir,
         this.state.currentYear,
         this.state.observe_dst,
         this.state.dst_from_date,
         this.state.dst_to_date,
         this.state.dst_deviation
      );
   };

   render() {
      const {
         loading,
         offline,
         currentPage,
         currentCity,
         currentCountry,
         selectedCountry,
         selectedCity,
         cities,
         countries,
         data,
         todaySalahTimes,
         monthlySalahTimes,
         selectedMonth,
         todayDate,
         openSideBar,
         darkMode,
         fajr_no_taqdir,
         selectedYear,
         currentYear,
         observe_dst,
         dst_from_date,
         dst_to_date,
         dst_deviation,
         lang,
         currentLang,
      } = this.state;

      const darkTheme = createTheme({
         palette: {
            mode: 'dark',
         },
      });

      const lightTheme = createTheme({
         palette: {
            mode: 'light',
         },
      });

      return (
         <CacheProvider value={currentLang === 'ar' ? cacheRtl : cacheLTR}>
            <ThemeProvider theme={currentLang === 'ar' ? RTLtheme : LTRtheme}>
               <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
                  <div className="App">
                     <TopBar
                        currentCity={currentCity}
                        currentCountry={currentCountry}
                        todayDate={todayDate}
                        currentPage={currentPage}
                        selectedMonth={selectedMonth}
                        currentYear={currentYear}
                        changeSelectedMonth={this.changeSelectedMonth}
                        toggleDrawer={this.toggleDrawer}
                        theme={darkMode}
                        todaySalahTimes={todaySalahTimes}
                        lang={lang}
                        currentLang={currentLang}
                     />
                     {(!offline || data !== null) && !loading && currentPage === 'DailyTimes' && (
                        <DailyTimes theme={darkMode} todaySalahTimes={todaySalahTimes} lang={lang} currentLang={currentLang} />
                     )}
                     {(!offline || data !== null) && !loading && currentPage === 'MonthlyTimes' && (
                        <MonthlyTimes monthlySalahTimes={monthlySalahTimes} selectedMonth={selectedMonth} lang={lang} currentLang={currentLang} />
                     )}
                     {offline && loading && (
                        <Grid
                           container
                           sx={{
                              color: '#000',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: '30vh',
                              fontSize: '24px',
                              fontWeight: '300',
                           }}
                        >
                           {lang[currentLang].noInternet}
                        </Grid>
                     )}
                     <BottomNavigation lang={lang} currentLang={currentLang} setcurrentPage={this.setcurrentPage} />
                     <SideBar
                        toggleDrawer={this.toggleDrawer}
                        openSideBar={openSideBar}
                        cities={cities}
                        countries={countries}
                        currentCity={currentCity}
                        currentCountry={currentCountry}
                        selectedCity={selectedCity}
                        selectedCountry={selectedCountry}
                        setSelectedCity={this.setSelectedCity}
                        setSelectedCountry={this.setSelectedCountry}
                        set_fajr_no_taqdir={this.set_fajr_no_taqdir}
                        fajr_no_taqdir={fajr_no_taqdir}
                        set_observe_dst={this.set_observe_dst}
                        observe_dst={observe_dst}
                        set_dst_from_date={this.set_dst_from_date}
                        dst_from_date={dst_from_date}
                        set_dst_to_date={this.set_dst_to_date}
                        dst_to_date={dst_to_date}
                        set_dst_deviation={this.set_dst_deviation}
                        dst_deviation={dst_deviation}
                        selectedYear={selectedYear}
                        setSelectedYear={this.setSelectedYear}
                        changeCurrentCity={this.changeCurrentCity}
                        theme={darkMode}
                        setDarkMode={this.setDarkMode}
                        downloadJSON={this.downloadJSON}
                        DownloadCSV={this.DownloadCSV}
                        DownloadExcel={this.DownloadExcel}
                        DownloadPDF={this.DownloadPDF}
                        formRef={this.formRef}
                        lang={lang}
                        currentLang={currentLang}
                        changeLanguage={this.changeLanguage}
                     />
                  </div>
               </ThemeProvider>
            </ThemeProvider>
         </CacheProvider>
      );
   }
}

export default App;

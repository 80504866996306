/* eslint-disable import/no-anonymous-default-export */
import ExtractData from '../Helpers/ExtractData.js';
import ical from 'ical-generator';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const parameters = {
   taqdir_method: 'new_method',
   natural_motion_alignment_interpolation: true,
   longest_day_check: true,
};

export default {
   async getSalahData(city, country, fajr_no_taqdir, selectedYear, observe_dst, dst_from_date, dst_to_date, dst_deviation) {
      // Change parameters
      const newParameters = {
         ...parameters,
         ...ExtractData.getCityData(country, city),
         year: selectedYear,
         city: city,
         fajr_no_taqdir: !fajr_no_taqdir,
         observe_dst: observe_dst,
         dst_from_date: convertDateToISO(dst_from_date),
         dst_to_date: convertDateToISO(dst_to_date),
         dst_deviation: dst_deviation,
      };

      if (dst_from_date === null) delete newParameters['dst_from_date'];
      if (dst_to_date === null) delete newParameters['dst_to_date'];

      // Send request
      const response = await fetch(`${SERVER_URL}/json`, {
         method: 'POST',
         body: JSON.stringify(newParameters),
         headers: {
            'Content-Type': 'application/json',
         },
      }).catch(() => null);
      if (!response) return null;
      const data = await response.json().then((data) => data);
      return data;
   },

   DownloadJSON(data) {
      const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
      const link = document.createElement('a');
      link.href = jsonString;
      link.download = data.city + '.json';
      link.click();
   },

   async DownloadCSV(city, country, fajr_no_taqdir, selectedYear, observe_dst, dst_from_date, dst_to_date, dst_deviation) {
      // Change parameters
      const newParameters = {
         ...parameters,
         ...ExtractData.getCityData(country, city),
         year: selectedYear,
         city: city,
         fajr_no_taqdir: !fajr_no_taqdir,
         observe_dst: observe_dst,
         dst_from_date: convertDateToISO(dst_from_date),
         dst_to_date: convertDateToISO(dst_to_date),
         dst_deviation: dst_deviation,
      };

      // Send request
      const response = await fetch(`${SERVER_URL}/csv`, {
         method: 'POST',
         body: JSON.stringify(newParameters),
         headers: {
            'Content-Type': 'application/json',
         },
      }).catch(() => null);
      if (!response) return null;
      const data = await response.text().then((data) => data);

      const csvString = `data:text/csv;chatset=utf-8,${encodeURIComponent(data)}`;
      const link = document.createElement('a');
      link.href = csvString;
      link.download = city + '.csv';
      link.click();
   },

   async DownloadCalendar(city, country, fajr_no_taqdir, selectedYear, observe_dst, dst_from_date, dst_to_date, dst_deviation) {
      const data = await this.getSalahData(city, country, fajr_no_taqdir, selectedYear, observe_dst, dst_from_date, dst_to_date, dst_deviation);
      if (!data) return;

      const cal = ical({ name: `${city} Prayer Times` });

      data.prayer_times.forEach((prayer) => {
         const date = new Date(prayer.date);
         ['Fajr', 'Shuruq', 'Zuhr', 'Assr', 'Maghrib', 'Ishaa'].forEach((time) => {
            const timeParts = prayer[time.toLowerCase()].split(':');
            const startDate = new Date(date);
            startDate.setHours(timeParts[0], timeParts[1]);

            const endDate = new Date(startDate);
            endDate.setMinutes(startDate.getMinutes() + 30);

            cal.createEvent({
               start: startDate,
               end: endDate,
               summary: time,
            });
         });
      });

      // Generate iCal data and trigger download
      const blob = new Blob([cal.toString()], { type: 'text/calendar;charset=utf-8' });
      const url = URL.createObjectURL(blob);

      // Trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = `${city}.ics`;
      link.click();

      /* // Cleanup
      URL.revokeObjectURL(url); */
   },

   async DownloadExcel(city, country, fajr_no_taqdir, selectedYear, observe_dst, dst_from_date, dst_to_date, dst_deviation) {
      // Change parameters
      const newParameters = {
         ...parameters,
         ...ExtractData.getCityData(country, city),
         year: selectedYear,
         city: city,
         fajr_no_taqdir: !fajr_no_taqdir,
         observe_dst: observe_dst,
         dst_from_date: convertDateToISO(dst_from_date),
         dst_to_date: convertDateToISO(dst_to_date),
         dst_deviation: dst_deviation,
      };

      const response = await fetch(`${SERVER_URL}/excel`, {
         method: 'POST',
         body: JSON.stringify(newParameters),
         headers: {
            'Content-Type': 'application/json',
         },
      }).catch(() => null);

      const data = await response.arrayBuffer();
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = city + '.xlsx';
      link.click();
   },
   async DownloadPDF(city, country, fajr_no_taqdir, selectedYear, observe_dst, dst_from_date, dst_to_date, dst_deviation) {
      // Change parameters
      const newParameters = {
         ...parameters,
         ...ExtractData.getCityData(country, city),
         year: selectedYear,
         city: city,
         fajr_no_taqdir: !fajr_no_taqdir,
         observe_dst: observe_dst,
         dst_from_date: convertDateToISO(dst_from_date),
         dst_to_date: convertDateToISO(dst_to_date),
         dst_deviation: dst_deviation,
      };

      const response = await fetch(`${SERVER_URL}/pdf`, {
         method: 'POST',
         body: JSON.stringify(newParameters),
         headers: {
            'Content-Type': 'application/json',
         },
      }).catch(() => null);

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = city + '.pdf';
      link.click();
   },
};

function convertDateToISO(date) {
   if (!date) return date;
   const dateSplit = date.split('-');
   const newDate = new Date(dateSplit[1] + '/' + dateSplit[2] + '/' + dateSplit[0]);
   return newDate.toISOString();
}

import CitiesData from './data.json';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
   extractDailySalahTimes(data, dayDate, chosenYear) {
      const month = dayDate.getMonth() + 1;
      let day = dayDate.getDate();

      // If not current year and month is febraury and day is 29 set day to 28
      if (new Date().getFullYear !== chosenYear && month === 1 && day === 29) day = 28;
      return data['times'][month][day];
   },

   extractMonthlySalahTimes(data, monthDate) {
      const month = monthDate.getMonth() + 1;

      // Turn data to array
      let monthData = [];
      for (const [key] of Object.entries(data['times'][month])) {
         monthData.push(data['times'][month][key]);
      }
      return monthData;
   },

   getCityNames(country) {
      const cityNames = [];
      if (CitiesData.countries[country]) {
         for (const [key] of Object.entries(CitiesData.countries[country])) {
            if (key !== 'altnames') cityNames.push(key);
         }
         return cityNames;
      }
      return [];
   },
   getCountryNames() {
      const countryNames = [];
      for (const [key] of Object.entries(CitiesData.countries)) {
         countryNames.push(key);
      }
      return countryNames;
   },
   getCityData(country, city) {
      return CitiesData.countries[country][city];
   },
   getCityAltName(country, city, lang) {
      return CitiesData.countries[country][city]['altnames'][lang];
   },
   getCountryAltName(country, lang) {
      return CitiesData.countries[country]['altnames'][lang];
   },
};

import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ExtractData from '../Helpers/ExtractData';

export default function SideBar(props) {
   const [showParameter, setShowParameter] = React.useState(false);

   const MaterialUISwitch = styled(Switch)(({ theme }) => ({
      width: 62,
      height: 34,
      padding: 7,
      '& .MuiSwitch-switchBase': {
         margin: 1,
         padding: 0,
         transform: 'translateX(6px)',
         '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
               backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                  '#fff'
               )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
               opacity: 1,
               backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
         },
      },
      '& .MuiSwitch-thumb': {
         backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
         width: 32,
         height: 32,
         '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
               '#fff'
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
         },
      },
      '& .MuiSwitch-track': {
         opacity: 1,
         backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
         borderRadius: 20 / 2,
      },
   }));

   return (
      <Drawer anchor="right" open={props.openSideBar} onClose={props.toggleDrawer(false)}>
         <Grid
            container
            className={props.theme === false ? 'topbar-gradient-color-light' : 'topbar-gradient-color-dark'}
            sx={{
               padding: '8px',
               fontWeight: 300,
               flexDirection: 'row',
               color: '#fff',
            }}
         >
            <Grid item xs={24}>
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IconButton onClick={props.toggleDrawer(false)}>
                     <ChevronRightIcon
                        sx={{
                           color: '#fff',
                           fontSize: 35,
                        }}
                     />
                  </IconButton>
                  <FormGroup style={props.currentLang === 'ar' ? { marginRight: '10px' } : null}>
                     <FormControlLabel
                        control={<MaterialUISwitch sx={{ m: 1 }} checked={props.theme} onClick={() => props.setDarkMode(props.theme)} />}
                        label={props.lang[props.currentLang].darkMode}
                     />
                  </FormGroup>
               </div>
               <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                  <ToggleButtonGroup exclusive value={props.currentLang} onChange={props.changeLanguage}>
                     <ToggleButton value="en">EN</ToggleButton>
                     <ToggleButton value="de">DE</ToggleButton>
                     <ToggleButton value="ar">عربى</ToggleButton>
                  </ToggleButtonGroup>
               </div>
            </Grid>
            <Grid
               item
               xs={24}
               sx={{
                  textAlign: 'center',
                  fontSize: '20px',
               }}
            >
               {props.lang[props.currentLang].selectedCity}
            </Grid>
            <Grid
               item
               xs={24}
               sx={{
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginTop: '10px',
               }}
            >
               {ExtractData.getCityAltName(props.currentCountry, props.currentCity, props.currentLang)} ,{' '}
               {ExtractData.getCountryAltName(props.currentCountry, props.currentLang)}
            </Grid>
         </Grid>
         <Grid
            container
            sx={{
               padding: '20px',
               paddingBottom: '0px',
            }}
         >
            <Grid item xs={24}>
               <Autocomplete
                  disableClearable
                  options={props.countries.map((option) => ({ label: ExtractData.getCountryAltName(option, props.currentLang), value: option }))}
                  onChange={(event, newValue) => props.setSelectedCountry(newValue.value)}
                  renderInput={(params) => (
                     <TextField
                        {...params}
                        label={props.lang[props.currentLang].country}
                        InputProps={{
                           ...params.InputProps,
                           type: 'search',
                        }}
                     />
                  )}
               />
            </Grid>
         </Grid>
         <Grid
            container
            sx={{
               padding: '20px',
               paddingBottom: '0px',
            }}
         >
            <Grid item xs={24}>
               <Autocomplete
                  disableClearable
                  options={props.cities.map((option) => ({
                     label: ExtractData.getCityAltName(props.selectedCountry, option, props.currentLang),
                     value: option,
                     key: option,
                  }))}
                  renderOption={(props, option) => {
                     return (
                        <li {...props} value={option.value} key={option.key}>
                           {option.label}
                        </li>
                     );
                  }}
                  onChange={(event, newValue) => {
                     props.setSelectedCity(newValue.value, true);
                  }}
                  renderInput={(params) => (
                     <TextField
                        {...params}
                        label={props.lang[props.currentLang].city}
                        InputProps={{
                           ...params.InputProps,
                           type: 'search',
                        }}
                     />
                  )}
               />
            </Grid>
         </Grid>
         <Grid
            container
            sx={{
               padding: '20px',
            }}
         >
            <Grid item xs={24} sx={props.currentLang === 'ar' ? { textAlign: 'left' } : { marginBottom: '20px', textAlign: 'right' }}>
               <FormControlLabel
                  control={
                     <Switch
                        onClick={() => {
                           setShowParameter(!showParameter);

                           // Reset All
                           props.set_fajr_no_taqdir(true);
                           props.setSelectedYear(new Date().getFullYear());
                           props.set_observe_dst(true);
                           props.set_dst_from_date(null);
                           props.set_dst_to_date(null);
                           props.set_dst_deviation(1);
                           props.changeCurrentCity({
                              fajr_no_taqdir: true,
                              selectedYear: new Date().getFullYear(),
                              observe_dst: true,
                              dst_from_date: null,
                              dst_to_date: null,
                              dst_deviation: 1,
                           });
                        }}
                        checked={showParameter}
                     />
                  }
                  label={props.lang[props.currentLang].extraOptions}
               />
            </Grid>
            <Grid item xs={24}>
               {showParameter && (
                  <form ref={props.formRef}>
                     <FormControlLabel
                        control={
                           <Switch
                              color="success"
                              checked={props.fajr_no_taqdir}
                              onClick={() => props.set_fajr_no_taqdir(props.fajr_no_taqdir ? false : true, true)}
                           />
                        }
                        sx={{ marginBottom: '10px' }}
                        label={props.lang[props.currentLang].fajrTaqdir}
                     />
                     <FormControl fullWidth>
                        <InputLabel id="year-select">{props.lang[props.currentLang].year}</InputLabel>
                        <Select
                           labelId="year-select"
                           value={props.selectedYear}
                           label={props.lang[props.currentLang].year}
                           onChange={(e) => {
                              props.setSelectedYear(e.target.value, true);
                              props.set_dst_from_date(null);
                              props.set_dst_to_date(null);
                              props.set_observe_dst(true);
                           }}
                        >
                           {[...Array(15)].map((e, i) => {
                              return (
                                 <MenuItem key={i} value={2015 + i}>
                                    {2015 + i}
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>
                     <Grid container sx={{ marginTop: '20px' }}>
                        <Grid item xs={24}>
                           <FormControlLabel
                              control={
                                 <Switch
                                    color="success"
                                    onClick={() => {
                                       const observe_dst = props.observe_dst ? false : true;
                                       props.set_observe_dst(observe_dst);
                                       props.set_dst_from_date(null);
                                       props.set_dst_to_date(null);
                                       props.set_dst_deviation(1);
                                       props.changeCurrentCity({
                                          observe_dst: observe_dst,
                                          dst_from_date: null,
                                          dst_to_date: null,
                                          dst_deviation: 1,
                                       });
                                    }}
                                    checked={props.observe_dst}
                                 />
                              }
                              label={props.lang[props.currentLang].daylightSaving}
                           />
                        </Grid>
                        {props.observe_dst && (
                           <div style={{ width: '100%' }}>
                              <div
                                 style={
                                    props.currentLang === 'ar'
                                       ? { display: 'flex', alignItems: 'center', justifyContent: 'end', flexDirection: 'row-reverse' }
                                       : { display: 'block' }
                                 }
                              >
                                 <label htmlFor="start" style={props.currentLang === 'ar' ? { marginLeft: '8px' } : { marginRight: '8px' }}>
                                    {props.currentLang === 'ar' ? ':' : null} {props.lang[props.currentLang].fromDate}
                                    {props.currentLang === 'ar' ? null : ':'}
                                 </label>
                                 <input
                                    type="date"
                                    id="start"
                                    name="trip-start"
                                    style={{
                                       backgroundColor: 'transparent',
                                       border: '1px solid grey',
                                       padding: '10px',
                                       borderRadius: '10px',
                                       color: 'grey',
                                    }}
                                    min={props.selectedYear + '-01-01'}
                                    max={props.selectedYear + '-12-31'}
                                    defaultValue={props.dst_from_date}
                                    required
                                    onBlur={(e) => {
                                       props.set_dst_from_date(e.target.value, true);
                                    }}
                                 />
                              </div>

                              <div
                                 style={
                                    props.currentLang === 'ar'
                                       ? {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                            flexDirection: 'row-reverse',
                                            marginTop: '10px',
                                         }
                                       : { display: 'block', marginTop: '10px' }
                                 }
                              >
                                 <label htmlFor="start" style={props.currentLang === 'ar' ? { marginLeft: '8px' } : { marginRight: '8px' }}>
                                    {props.currentLang === 'ar' ? ':' : null} {props.lang[props.currentLang].toDate}
                                    {props.currentLang === 'ar' ? null : ':'}
                                 </label>
                                 <input
                                    type="date"
                                    id="end"
                                    name="trip-end"
                                    style={{
                                       backgroundColor: 'transparent',
                                       border: '1px solid grey',
                                       padding: '10px',
                                       borderRadius: '10px',
                                       color: 'grey',
                                    }}
                                    defaultValue={props.dst_to_date}
                                    min={props.selectedYear + '-01-01'}
                                    max={props.selectedYear + '-12-31'}
                                    required
                                    onBlur={(e) => {
                                       props.set_dst_to_date(e.target.value, true);
                                    }}
                                 />
                              </div>

                              <div
                                 style={
                                    props.currentLang === 'ar'
                                       ? {
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'end',
                                            flexDirection: 'row-reverse',
                                            marginTop: '10px',
                                         }
                                       : { display: 'block', marginTop: '10px' }
                                 }
                              >
                                 <label htmlFor="start" style={props.currentLang === 'ar' ? { marginLeft: '8px' } : { marginRight: '8px' }}>
                                    {props.currentLang === 'ar' ? ':' : null} {props.lang[props.currentLang].change}
                                    {props.currentLang === 'ar' ? null : ':'}
                                 </label>
                                 <select
                                    style={{
                                       backgroundColor: 'transparent',
                                       border: '1px solid grey',
                                       padding: '10px',
                                       borderRadius: '10px',
                                       color: 'grey',
                                    }}
                                    defaultValue={props.dst_deviation}
                                    onChange={(e) => props.set_dst_deviation(e.target.value, true)}
                                 >
                                    <option value="-2">-02:00</option>
                                    <option value="-1.5">-01:30</option>
                                    <option value="-1">-01:00</option>
                                    <option value="-0.5">-00:30</option>
                                    <option value="0">00:00</option>
                                    <option value="0.5">+00:30</option>
                                    <option value="1">+01:00</option>
                                    <option value="1.5">+01:30</option>
                                    <option value="2">+02:00</option>
                                 </select>
                              </div>
                           </div>
                        )}
                     </Grid>
                  </form>
               )}
            </Grid>
         </Grid>

         {/* Seperator */}
         <hr style={{ borderTop: '1px solid #bbb', borderRadius: '5px', width: '80%' }} />

         {/* Download */}
         <Grid
            container
            sx={{
               padding: '5px',
            }}
         >
            <Grid
               item
               xs={24}
               sx={{
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: 500,
                  marginTop: '10px',
                  marginBottom: '10px',
               }}
            >
               {props.lang[props.currentLang].download}
            </Grid>
            <Grid item xs={24} sx={{ textAlign: 'center' }}>
               <Button variant="outlined" color="success" sx={{ width: '60%', marginBottom: '10px' }} onClick={props.downloadJSON}>
                  JSON
               </Button>
            </Grid>
            <Grid item xs={24} sx={{ textAlign: 'center' }}>
               <Button variant="outlined" color="success" sx={{ width: '60%', marginBottom: '10px' }} onClick={props.DownloadCSV}>
                  CSV
               </Button>
            </Grid>
            {/* <Grid item xs={24} sx={{ textAlign: 'center' }}>
               <Button variant="outlined" color="success" sx={{ width: '60%', marginBottom: '10px' }} onClick={props.DownloadCalendar}>
                  Calendar
               </Button>
            </Grid> */}
            <Grid item xs={24} sx={{ textAlign: 'center' }}>
               <Button variant="outlined" color="success" sx={{ width: '60%', marginBottom: '10px' }} onClick={props.DownloadExcel}>
                  EXCEL
               </Button>
            </Grid>
            <Grid item xs={24} sx={{ textAlign: 'center' }}>
               <Button variant="outlined" color="success" sx={{ width: '60%', marginBottom: '10px' }} onClick={props.DownloadPDF}>
                  PDF
               </Button>
            </Grid>
         </Grid>
      </Drawer>
   );
}

import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
// import ArticleIcon from '@mui/icons-material/Article';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  &.Mui-selected {
    color: #00b368;
  }
`);

export default function FixedBottomNavigation(props) {
   const [value, setValue] = React.useState(0);
   return (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={0}>
         <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
               setValue(newValue);
               if (newValue === 0) {
                  props.setcurrentPage('DailyTimes');
               } else if (newValue === 1) {
                  props.setcurrentPage('MonthlyTimes');
               }
               // else if (newValue === 2) {
               //   props.setcurrentPage('ExtraMaterial');
               // }
            }}
         >
            <BottomNavigationAction label={props.lang[props.currentLang].day} icon={<RestoreIcon />} />
            <BottomNavigationAction label={props.lang[props.currentLang].month} icon={<DateRangeRoundedIcon />} />
            {/* <BottomNavigationAction label='Extra' icon={<ArticleIcon />} /> */}
         </BottomNavigation>
      </Paper>
   );
}

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';

export default function MonthlyTimes(props) {
  return (
    <TableContainer component={Paper} sx={{ marginBottom: '56px' }}>
      <CssBaseline />
      <Table
        sx={{ minWidth: '100vw', width: '100vw', fontWeight: 900 }}
        aria-label='Monthly Times'
      >
        <TableBody>
          {props.monthlySalahTimes.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell
                sx={{
                  width: 'calc(100vw/8)',
                  padding: '16px 0px',
                  fontWeight: 700,
                }}
                align='center'
              >
                {Number(index + 1).toString()}
              </TableCell>
              <TableCell
                sx={{
                  width: 'calc(100vw/8)',
                  padding: '16px 0px',
                }}
                align='center'
              >
                {row['hijri_date']}
              </TableCell>
              <TableCell
                sx={{ width: 'calc(100vw/8)', padding: '16px 0px' }}
                align='center'
              >
                {row['p1']['t']}
              </TableCell>
              <TableCell
                sx={{ width: 'calc(100vw/8)', padding: '16px 0px' }}
                align='center'
              >
                {row['p2']['t']}
              </TableCell>
              <TableCell
                sx={{ width: 'calc(100vw/8)', padding: '16px 0px' }}
                align='center'
              >
                {row['p3']['t']}
              </TableCell>
              <TableCell
                sx={{ width: 'calc(100vw/8)', padding: '16px 0px' }}
                align='center'
              >
                {row['p4']['t']}
              </TableCell>
              <TableCell
                sx={{ width: 'calc(100vw/8)', padding: '16px 0px' }}
                align='center'
              >
                {row['p5']['t']}
              </TableCell>
              <TableCell
                sx={{ width: 'calc(100vw/8)', padding: '16px 0px' }}
                align='center'
              >
                {row['p6']['t']}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
